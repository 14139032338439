<template>
  <div>
    <Visualizador v-if="solicitud_id" tipo_visualizacion="voto" />
  </div>
</template>

<script>
import Visualizador from '@/apps/analyticpoint/pages/Solicitud/Visualizador';

export default {
  components: {
    Visualizador
  },
  data() {
    return {
      solicitud_id: null
    }
  },
  mounted() {
    this.solicitud_id = this.$route.params.solicitud
  }
}
</script>